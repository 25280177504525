import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {graphql} from "gatsby";
import NavigationList from "../components/navigation-list";

class PublicationsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const exhibitions = get(this, 'props.data.allContentfulPublikation.edges')
    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Publikationen'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <NavigationList
          links={exhibitions.map((pub => ({path: "/publikation/" + pub.node.slug,
            text: pub.node.titleShort})))}>
        </NavigationList>
      </Layout>
    )
  }
}

export default PublicationsIndex

export const pageQuery = graphql`
  query PublikationIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPublikation {
      edges {
        node {
          title
          titleShort
          slug
        }
      }
    }
  }
`


